<template>
  <div>
    <CCard>
      <CCardHeader>
        {{ $t('Upload') }}
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4" v-for="fileType in availableFileTypes" :key="fileType.name">
            <h4>{{ fileType.name }}</h4>
            <FileDragDrop
              :data-cy="`upload-${fileType.name.toLowerCase()}`"
              :title='fileType.name'
              @upload="(file) => handleUpload(file, fileType.id)"
              :allowedExtensions="fileType.allowed_extensions"
              :disabled="!!fileIdUploadState[fileType.id]"
            />
            <div
              v-if="fileIdUploadState[fileType.id]"
              class="file-upload-progress-container"
            >
              <FileUploadProgress
                :fileName="fileIdUploadState[fileType.id].name"
                :uploadProgress="fileIdUploadState[fileType.id].progress"
                uploadInfo="Uploading"
                @cancel="cancelUpload(fileType.id)"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        {{ $t('Uploaded files') }}
      </CCardHeader>
      <CCardBody>
        <DotsLoader v-if="isLoading" />
        <table-component
            v-else-if="files.length"
            :data="files"
            :columns="['filename', 'size', 'transcription', 'modified', 'actions']"
            :filterExcludeColumns="['actions']"
            defaultOrderBy="-modified"
        >
          <template #filename="{ row }">
            <div
              class="link"
              @click="
                $downloadFile({
                  name: row.filename,
                  fileId: row.file_id,
                })
              "
              data-cy="uploaded-filename"
            >
              {{ row.filename }}
            </div>
          </template>
          <template #actions="{ row }">
            <div class="link px-1" @click="removeFile(row)">
              {{ $t('Remove') }}
            </div>
          </template>
        </table-component>
        <span v-else>
          {{ $t('No files uploaded') }}
        </span>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { FilesService } from '@/services/files';

export default {
  name: 'Files',
  data() {
    return {
      fileIdUploadState: {},
      files: [],
      availableFileTypes: [],
      isLoading: true,
    };
  },
  mounted() {
    this.fetchAvailableFileTypes();
    this.fetchUploadedFiles();
  },
  methods: {
    async removeFile(file) {
      await FilesService.removeFile({ name: file.filename, file_id: file.file_id });
      await this.fetchUploadedFiles();
    },
    async fetchAvailableFileTypes() {
      this.availableFileTypes = await FilesService.fetchAvailableFileTypes();
    },
    async fetchUploadedFiles() {
      this.files = await FilesService.fetchFiles();
      this.isLoading = false;
    },
    cancelUpload(fileId) {
      this.fileIdUploadState[fileId].xhr?.abort();
      this.fileIdUploadState[fileId] = null;
    },
    async handleUpload(file, fileId) {
      this.fileIdUploadState[fileId] = { progress: 0, name: file.name };
      await this.$uploadFile({
        fileId,
        file,
        onStateChange: (xhr) => (this.fileIdUploadState[fileId].xhr = xhr),
        onProgress: (progress) =>
          (this.fileIdUploadState[fileId].progress = progress),
      });
      this.fileIdUploadState[fileId] = null;
      await this.fetchUploadedFiles();
    },
  },
};
</script>
